import React, { Component } from "react";

class Relax extends Component {
  render() {
    return (
      <section id="relax">
        <div style={{ maxWidth: "300px", textAlign: "left", margin: "0 auto" }}>
          <h2> Let’s spend a weekend away at Home together. </h2>

          <p>
            Let’s come away from it all,
            <br />
            the little ‘t’ truths,
            <br />
            the ‘not enoughs’ & the ‘should’ves’;
            <br />
            let’s come Home together.
          </p>

          <p>
            There’s a seat reserved for each of us inside The Father’s House.
            Let’s find our seats together & enjoy good food around His Table.
            <p />
            <p>
              Let’s talk deeply with each other. Let’s laugh & sing together.
              Let’s learn & grow together, at Home together, like children do.
            </p>
            <p>
              “You are all sons of light and sons of the day. We are not of the
              night nor of darkness.”
              <br />
              <b>I Thessalonians 5:5</b>
            </p>
            <p>
              “How precious is Your lovingkindness, O God! Therefore the
              children of men put their trust under the shadow of Your wings.
              They are abundantly satisfied with the fullness of Your house, And
              You give them drink from the river of Your pleasures. For with You
              is the fountain of life; In Your light we see light.”
              <br />
              <b>‭‭Psalms‬ ‭36: 7-9‬</b>
            </p>
          </p>
        </div>
      </section>
    );
  }
}

export default Relax;
