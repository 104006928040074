import React, { Component } from "react";
import { Link } from "react-router-dom";
import Nav from "./Nav";

export const CampImage = () => {
  return (
    <div style={{ display: "flex", marginBottom: "4em", marginTop: "4em" }}>
      <div className="camp-image-container" style={{ display: "flex" }}>
        <img className="camp-image" src="./images/bg.jpg"></img>
        <img className="camp-image" src="./images/bg2.jpg"></img>
      </div>
    </div>
  );
};
