import React, { Component } from "react";
import { Link } from "react-router-dom";

class BookingSuccess extends Component {
  render() {
    return (
      <section id="booking-success">
        <h1>Great! You've registered for SEQ Sisters 2024</h1>
        <br />
        <br />
        <p>What's Next?</p>
        <a href="/#what-to-bring" className="">
          <button>Show me what to pack</button>
        </a>
      </section>
    );
  }
}

export default BookingSuccess;
