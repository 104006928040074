import React, { Component } from "react";
import "./calendar.css";

class Calendar extends Component {
  render() {
    let schedule = {
      Saturday: [
        { t: "8:30am", e: "coffee run!" },
        { t: "9am", e: "team challenge" },
        { t: "10:30am", e: "morning tea" },
        { t: "11am", e: "group huddle" },
        { t: "12:30pm", e: "lunch" },
        { t: "2pm", e: "workshop " },
        { t: "4pm", e: "yoga / nap / chat" },
        { t: "5:30pm", e: "Disney dress-up!" },
        { t: "6pm", e: "dinner" },
        { t: "7pm", e: "Saturday night entertainment" },
        { t: "8pm", e: "ballet with Paris" },
        { t: "9pm", e: "jammies / games / mug cakes" },
        { t: "11pm", e: "quiet time" },
      ],
      Sunday: [
        { t: "8am", e: "breakfast" },
        { t: "9:30", e: "testimonies and songs " },
        { t: "10:30am", e: "morning tea" },
        { t: "11am", e: "morning meeting" },
        { t: "12:30pm", e: "lunch" },
        { t: "2pm", e: "depart" },
      ],
    };

    return (
      <section id="calendar">
        <h1>SEQ Sisters Schedule</h1>
        <br />
        <div className="schedule-container" style={{ textAlign: "left" }}>
          <h2>Friday</h2>
          <div>
            <div className="time">anytime from 6:30pm</div>
            <div className="direction">
              Enjoy dinner before arriving at camp
            </div>
          </div>
          <div>
            <div className="time">upon arrival</div>
            <div className="direction">
              Find your room, settle in, play a game, have a chat, make a mug
              cake.
            </div>
          </div>
          <br />
          {Object.keys(schedule).map((day) => (
            <React.Fragment key={day}>
              <h2>{day}</h2>
              <ul>
                {schedule[day].map((i) => (
                  <li className="schedule-item" key={i.t}>
                    <span className="time">{i.t}</span>
                    <div className="item-dots" />
                    <div className="item-name">{i.e}</div>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          ))}
        </div>
        <br />
        <br />
        <br />
        <h1>Site Map</h1>
        <br />
        <img className="site_map" src="./images/qccc_tamborine_site_map.jpg" />
        <br />
        <a href="./assets/qccc_tamborine_site_map.pdf" target="_blank">
          View PDF version here.
        </a>
      </section>
    );
  }
}

export default Calendar;
