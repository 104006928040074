import React, { Component } from "react";
import "./default.css";
import "./media-queries.css";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import WhereIsIt from "./Components/WhereIsIt";
import WhatToBring from "./Components/WhatToBring";
import Register from "./Components/Register";
import BeginRegistration from "./Components/BeginRegistration";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import Nav from "./Components/Nav";
import Calendar from "./Components/Calendar";
import BookingSuccess from "./Components/BookingSuccess";
import Relax from "./Components/Relax";
import { CampImage } from "./Components/CampImage";

class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route path="/register">
              <div>
                <Nav />
                <BeginRegistration />
              </div>
            </Route>
            <Route path="/calendar">
              <div>
                <Nav />
                <Calendar />
                <Footer />
              </div>
            </Route>
            <Route path="/booking-success">
              <div>
                <Nav />
                <BookingSuccess />
                <Footer />
              </div>
            </Route>
            <Route path="/">
              <div>
                {/* <div className="wave"></div> */}
                <Header />

                <WhereIsIt />
                <CampImage />
                <WhatToBring />
                <Register />
                <Footer />
              </div>
            </Route>
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
