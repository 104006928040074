import React, { Component } from "react";
import { Link } from "react-router-dom";

class Register extends Component {
  render() {
    return (
      <section id="register">
        <h2>Let's do this!</h2>
        <br />
        <Link to="/register" className="">
          <button className="register-btn ">Register Now</button>
        </Link>
        <br />
        <br />

        <p id="welcome">
          For further information please contact:
          <div>Nat 0404078582 (Sunny Coast)</div>
          <div>Vicki (Sunny Coast)</div>
        </p>
      </section>
    );
  }
}

export default Register;
