import React, { Component } from "react";

class WhereIsIt extends Component {
  render() {
    return (
      <section id="where-is-it">
        <div style={{ maxWidth: "300px", textAlign: "left", margin: "0 auto" }}>
          <h2>Location</h2>
          <div className="mt-4">
            <strong className="fw-800">Fri 19th</strong> – Arrive from 6:30pm
          </div>
          <div className="mb-4">
            <strong className="fw-800">Sun 21st</strong> – Leave at 2pm
          </div>
          <div>
            <strong className="fw-800 text-primary">QCCC Tamborine</strong>
          </div>
          <p>
            <a
              className="text-white"
              style={{ textDecoration: "underline" }}
              href="https://goo.gl/maps/tzWQWbG4eX5b5tQM9"
            >
              255 Beacon Rd, Tamborine Mountain.
            </a>
          </p>
          <p>Make sure you eat dinner before you come!</p>
          <p>Camp fees $200</p>
        </div>
      </section>
    );
  }
}

export default WhereIsIt;
