import React from "react";
import { Link } from "react-router-dom";
const Nav = () => {
  return (
    <nav id="nav-wrap">
      {/* <img className="logo-image" src="./images/logo.png" /> */}

      <a
        className="mobile-btn menu-open"
        href="#nav-wrap"
        title="Show navigation"
      >
        <i className="fas fa-bars"></i>
      </a>

      <ul id="nav" className="nav">
        <a className="mobile-btn" href="#home" title="Hide navigation">
          <i className="fas fa-times"></i>
        </a>
        <li className="current">
          <a href="/#home">SEQ Sisters 2024</a>
        </li>
        <li>
          <a href="/#where-is-it">Location</a>
        </li>
        <li>
          <a href="/#what-to-bring">My Suitcase</a>
        </li>
        <li>
          <Link to="/register" className="">
            Register Now
          </Link>
        </li>
        {/* <li>
          <Link to="/calendar" className="">
            Schedule
          </Link>
        </li> */}
      </ul>
    </nav>
  );
};

export default Nav;
