import React, { Component } from "react";

class WhatToBring extends Component {
  render() {
    return (
      <section id="what-to-bring">
        <div style={{ maxWidth: "300px", textAlign: "left", margin: "0 auto" }}>
          <h2>My Suitcase</h2>
          <p></p>
          <ul>
            <li>Warm bedding</li>
            <li>Bottom sheets</li>
            <li>Pillows</li>
            <li>Toiletries</li>
            <li>Towel</li>
            <li>A Bible, a pen and a note pad!</li>
            <li>Clothes & footwear you would be comfortable to be active in</li>
          </ul>
          <hr />
          <label>Optional extras</label>
          <ul>
            <li>Soccer balls, footballs, frisbees</li>
            <li>Board games, decks of cards etc</li>
            <li>Yoga Mat</li>
            <li>Musical instruments</li>
          </ul>
        </div>
      </section>
    );
  }
}

export default WhatToBring;
