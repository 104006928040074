import React, { Component } from "react";
import { Link } from "react-router-dom";
import Nav from "./Nav";

class Header extends Component {
  render() {
    return (
      <div
        id="home"
        style={{ minHeight: "1000px", paddingTop: "4em", paddingBottom: "4em" }}
      >
        <nav id="nav-wrap">
          {/* <img className="logo-image" src="./images/logo.png" /> */}

          <a
            className="mobile-btn menu-open"
            href="#nav-wrap"
            title="Show navigation"
          >
            <i className="fas fa-bars"></i>
          </a>

          <ul id="nav" className="nav">
            <a className="mobile-btn" href="#home" title="Hide navigation">
              <i className="fas fa-times"></i>
            </a>
            <li className="current">
              <a className="smoothscroll" href="#home">
                SEQ Sisters 2024
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#where-is-it">
                Location
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#what-to-bring">
                My Suitcase
              </a>
            </li>
            <li>
              <Link to="/register" className="">
                Register Now
              </Link>
            </li>
            {/* <li>
              <Link to="/calendar" className="">
                Schedule
              </Link>
            </li> */}
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text text-center">
            <sub />
            <br />
            <br />
            <h1 id="seq-sisters" style={{ marginBottom: 10 }}>
              SEQ Sisters <span style={{ letterSpacing: 0 }}>2024</span>
            </h1>

            <h3>Fri 19th - Sun 21st July 2024</h3>
            <sub />
            <br />
            <br />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "1em",
              }}
            >
              <img className="polaroid" src="./images/sisters2024.jpg" />
              <div
                style={{
                  maxWidth: "min(500px, 90vw)",
                  textAlign: "left",
                  margin: "0 auto",
                }}
              >
                <p id="welcome">Welcome to SEQ Sisters!</p>
                <p id="welcome">
                  This is where you’ll find information about our annual women’s
                  retreat in the South East corner of Queensland. We welcome
                  guests from all around Australia each year.
                </p>
                <p id="welcome">
                  The focus of these camps is to strengthen relationships
                  between women within the fellowship, to spend time focusing on
                  relevant Spiritual matters, to have great fun and to build
                  mentoring relationships across age groups.
                </p>
                <p id="welcome">
                  SEQ Sisters is an ‘all in’ weekend event for ladies aged 16
                  and up. If you’re going to celebrate your 16th birthday during
                  the calendar year, you're invited!
                </p>
                <p id="welcome">
                  Join us for an electrifying weekend of spiritual sustenance!
                  Partake in the rich blessing of time spent with your sisters,
                  enjoy an opportunity to strengthen relationships, and develop
                  a greater awareness of your own potential.
                </p>
              </div>
            </div>
            <br />
            <br />
            <Link to="/register" className="">
              <button className="register-btn ">Register Now</button>
            </Link>
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </div>
    );
  }
}

export default Header;
